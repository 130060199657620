<template>
  <div class="change_bg">

    <div class="modal_bg " :class="{'active' : isActive_m}">
      <div class="modal_content">
        <div class="m_card">
          <div class="m_card_main">
            <div class="m_body_card_main">

            </div>
            <div>
              <button class="exit_modal" @click="isActive_m=false">
                Закрыть
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.03125 0.861328L15.2526 15.08M1.03125 15.08L15.2526 0.861328" stroke="#1DBB95"
                        stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="">

            <div class="tab-page">
              <div class="tab d-flex">
                <button class="tablinks active d-flex" onclick="openCity(event, 'London')">Xizmatlar sifatini baholash</button>
              </div>


              <div id="main" class="tabcontent" style="display: block">
                <h3 style="padding-top: 15px">Xodimlarning muomala madaniyati va boshqa ko‘rsatkichlarni  baholash</h3>

                <form id="w0" @submit.prevent="mainSend()">
                  <input type="hidden" name="_csrf-frontend"
                         value="jQbqq5l7GjMdaGbjhNRVDZVXA7YCq-IAOr-IqgPqGpX5fLLS9T5MeXM6Kbf9o2x1oiNI4nPulUN26cPFcJN75g==">
                  <div class="row">
                    <div class="col-md-12 input">
                      <select name="CorruptionForm[type]" v-model="agency" id="" class="form-control">
                        <option value="Madaniy meros agentligi">Madaniy meros agentligi</option>
                        <option value="Buxoro viloyati madaniy meros  boshqarmasi">Buxoro viloyati madaniy meros  boshqarmasi</option>
                        <option value="Samarqand viloyati madaniy meros  boshqarmasi">Samarqand viloyati madaniy meros  boshqarmasi</option>
                        <option value="Andijon viloyati madaniy meros  boshqarmasi">Andijon viloyati madaniy meros  boshqarmasi</option>
                        <option value="Namangan viloyati madaniy meros  boshqarmasi">Namangan viloyati madaniy meros  boshqarmasi</option>
                        <option value="Farg'ona viloyati madaniy meros  boshqarmasi">Farg'ona viloyati madaniy meros  boshqarmasi</option>
                        <option value="Navoiy viloyati madaniy meros  boshqarmasi">Navoiy viloyati madaniy meros  boshqarmasi</option>
                        <option value="Qashqadaryo viloyati madaniy meros  boshqarmasi">Qashqadaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Surxondaryo viloyati madaniy meros  boshqarmasi">Surxondaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Sirdaryo viloyati madaniy meros  boshqarmasi">Sirdaryo viloyati madaniy meros  boshqarmasi</option>
                        <option value="Jizzax viloyati madaniy meros  boshqarmasi">Jizzax viloyati madaniy meros  boshqarmasi</option>
                        <option value="Toshkent shahar madaniy meros  boshqarmasi">Toshkent shahar madaniy meros  boshqarmasi</option>
                        <option value="Toshkent viloyati madaniy meros  boshqarmasi">Toshkent viloyati madaniy meros  boshqarmasi</option>
                        <option value="Qoraqalpog‘iston Respublikasi madaniy meros  boshqarmasi">Qoraqalpog‘iston Respublikasi madaniy meros  boshqarmasi</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">
                      <label for="">Xizmatni baholash</label>
                      <select v-model="checks" name="CorruptionForm[type]" id="" class="form-control">
                        <option value="A'lo">A'lo</option>
                        <option value="Yaxshi">yaxshi</option>
                        <option value="Qoniqarsiz">Qoniqarsiz</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">
                      <label for="">Sizga qancha muddatda xizmat ko'rsatildi</label>
                      <select v-model="date" name="CorruptionForm[type]" id="" class="form-control">
                        <option value="1 soat">1 soat</option>
                        <option value="1 kun ">1 kun</option>
                        <option value="Kuttirib quyishdi">Uzoq vaqt</option>
                      </select>
                    </div>
                    <div class="col-md-12 input">

                      <div class="text_areas1">
                        <textarea v-model="quiz" placeholder="Taklif va etirozlar"></textarea>
                      </div>
                    </div>

                  </div>
                  <button type="submit" class="btn btn-success input">Saqlash</button>
                </form>

              </div>


              <br>

            </div>
          </div>
        </div>

      </div>
    </div>

    <Navbar></Navbar>
    <section>

      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{ $store.state.language.lang[1] }}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{ $store.state.language.lang[2] }}
            </router-link>

          </div>
        </div>
        <div class="row">
          <div class=" col-12">
            <div class="m_body_title check_m">
              <h2>
                {{ $store.state.language.lang[2] }}</h2>
              <button class="btn main_check_btn" @click="monitoring()">Xizmatni baholash</button>
            </div>
            <!--            <div class="dec_input_group">-->
            <!--              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
            <!--                      d="M9.09091 1.81818C5.07429 1.81818 1.81818 5.07429 1.81818 9.09091C1.81818 13.1075 5.07429 16.3636 9.09091 16.3636C13.1075 16.3636 16.3636 13.1075 16.3636 9.09091C16.3636 5.07429 13.1075 1.81818 9.09091 1.81818ZM0 9.09091C0 4.07014 4.07014 0 9.09091 0C14.1117 0 18.1818 4.07014 18.1818 9.09091C18.1818 11.2739 17.4124 13.2771 16.1299 14.8443L19.7337 18.4481C20.0888 18.8031 20.0888 19.3787 19.7337 19.7337C19.3787 20.0888 18.8031 20.0888 18.4481 19.7337L14.8443 16.1299C13.2771 17.4124 11.2739 18.1818 9.09091 18.1818C4.07014 18.1818 0 14.1117 0 9.09091Z"-->
            <!--                      fill="#01011C"/>-->
            <!--              </svg>-->
            <!--              <div>-->
            <!--                <input type="text" placeholder="Введите номер или название документа">-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="row">
              <div class="col-xl-6 col-12" v-for="(item,index) in $store.state.services.results" :key="index">
                <div class="vacancy_card">
                  <div>
                    <div class="vacancy_title">{{ item.title }}</div>
                    <div class="vacancy_card_description" v-html="item.description">
                    </div>

                    <div class="vacancy_card_footer">
                      <div class="vacancy_buttons">
                        <a :href="item.url">
                          {{ $store.state.language.lang[67] }}

                          <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.6274 5.75678C13.3345 5.46389 12.8597 5.46389 12.5668 5.75678C12.2739 6.04967 12.2739 6.52455 12.5668 6.81744L13.6274 5.75678ZM18.8101 12.0001L19.3404 12.5304C19.4811 12.3898 19.5601 12.199 19.5601 12.0001C19.5601 11.8012 19.4811 11.6104 19.3404 11.4698L18.8101 12.0001ZM12.5668 17.1828C12.2739 17.4757 12.2739 17.9505 12.5668 18.2434C12.8597 18.5363 13.3345 18.5363 13.6274 18.2434L12.5668 17.1828ZM5.49609 11.2501C5.08188 11.2501 4.74609 11.5859 4.74609 12.0001C4.74609 12.4143 5.08188 12.7501 5.49609 12.7501V11.2501ZM18.7931 12.7501C19.2073 12.7501 19.5431 12.4143 19.5431 12.0001C19.5431 11.5859 19.2073 11.2501 18.7931 11.2501V12.7501ZM12.5668 6.81744L18.2798 12.5304L19.3404 11.4698L13.6274 5.75678L12.5668 6.81744ZM18.2798 11.4698L12.5668 17.1828L13.6274 18.2434L19.3404 12.5304L18.2798 11.4698ZM5.49609 12.7501H18.7931V11.2501H5.49609V12.7501Z"
                                fill="white"/>
                          </svg>


                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      dataitem: {},
      quiz:'',
      agency:'',
      date:'',
      checks:''
    }
  },
  mounted() {
    this.$store.dispatch('services')
  },
  methods: {
    mainSend(){
      this.$http.post('/api/appeal/',{
            "full_name":this.agency,
            "phone_number":this.checks,
            "email":"info@madaniymeros.uz",
            "body":this.quiz
          },
      )
          .then(()=>{
            this.$toasted.show("So'rov yuborildi", {
              duration: 1000,
              'type': 'info'
            })
            this.$router.push('/')
          })
          .catch(()=>{
            this.$toasted.show("Email xato yozilgan", {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    open_modal() {

    },
    myFunck(item = item) {
      this.dataitem = item
    },
    monitoring(){
      this.isActive_m=true
    }
  }
}
</script>
<style>
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tab-page {
  padding: 50px 5px;
  margin: 50px 0;
}

.input {
  margin-top: 25px;

}

.input label {
  font-size: 14px;
  color: black;
}
</style>
